export default {
    title: 'FUO Volumes',
    tabs: [
        {
            name: 'Share of m3 fulfilled - by Country',
            link: '/business/fuo/volumes/share-fulfilled'
        },
        {
            name: 'Share of m3 fulfilled - Trend',
            link: '/business/fuo/volumes/share-fulfilled/:market'
        },
        {
            name: 'm3 fulfilled – by Country',
            link: '/business/fuo/volumes/fulfilled'
        },
        {
            name: 'm3 fulfilled – Trend',
            link: '/business/fuo/volumes/fulfilled/:market'
        }
    ]
}
